<template>
  <div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <h1 class="text-3xl font-semibold"><i class="pi pi-map-marker text-2xl"></i> Distribuidores</h1>
      </div>
    </div>
    <div class="grid">
      
      <div class="col-12 md:col-7" id="distribuidores">
        <DataTable :value="distribuidores" :lazy="true" :loading="loading">
          <Column class="col-id" field="id" header="Código"></Column>
          <Column class="col-nome" field="nome" header="Nome"></Column>

          <Column class="col-actions">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning mr-2"
                @click="alterarForm(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="excluir(slotProps.data)"
              />
            </template>
          </Column>

          <template #empty> Nenhum Distribuidor encontrado. </template>
        </DataTable>
      </div>

      <div class="col-12 md:col-5">

        <div class="card p-fluid relative">
          <h5 v-if="distribuidor == null">Novo Distribuidor:</h5>
          <h5 v-else>Alteração de Distribuidor: </h5>

          <div class="grid">

            <div class="p-field col-12">
              <label for="nome" class="block mb-1">Nome</label>
              <InputText id="nome" type="text" v-model="nome" />
            </div>

            <div class="p-field col-12">
              <label for="senha" class="block mb-1">Senha</label>
              <Password
                id="senha"
                v-model="senha"
                :feedback="false"
                autocomplete="no"
                class="block"
                toggleMask
              ></Password>
            </div>

            <div class="p-field col-12">
              <label for="cep" class="block mb-1">CEP</label>
              <InputMask id="cep" v-model="cep" mask="99.999-999" @blur="validaCep" />
            </div>

            <div class="p-field col-12">
              <label for="endereco" class="block mb-1">Endereço</label>
              <InputText id="endereco" type="text" v-model="endereco" />
            </div>

            <div class="p-field col-12">

              <GoogleMap
                ref="mapa"
                api-key="AIzaSyC-mah_TjzO0fSC1X5AgUgj02oSKLU-tZc"
                style="width: 100%; height: 300px;"
                :center="mapCenter"
                :zoom="16"
              >
                <Marker 
                v-for="(m, index) in mapMarkers"
                :key="index"
                :options="m"
                @dragend="atualizaGPS"
                />
              </GoogleMap>

            </div>

            <div class="col-12" v-if="distribuidor == null">
              <Button
                label="Gravar Novo Distribuidor"
                icon="pi pi-check"
                class="p-button-success"
                @click="criar"
              ></Button>
            </div>

            <div class="col-12" v-else>
              <div class="grid">
                <div class="col-6">
                  <Button
                    label="Alterar"
                    icon="pi pi-check"
                    class="p-button-success"
                    @click="alterar"
                  ></Button>
                </div>
                <div class="col-6">
                  <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    class="p-button-warning"
                    @click="cancelarAlteracao"
                  ></Button>
                </div>
              </div>
            </div>

          </div>

          <div class="loading absolute top-0 bottom-0 left-0 right-0" v-show="validandoCep"></div>
        </div>

      </div>
    </div>

    <Toast position="top-right" />

  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import axios from "axios";

import { GoogleMap, Marker } from 'vue3-google-map';

export default {
  data() {
    return {

      // Dados cadastrados
      distribuidores: [],

      // Controle de Apresentação
      loading: false,
      validandoCep: false,

      // Dado selecionado
      distribuidor: null,

      // Campos do Formulário
      nome: "",
      senha: "",
      cep: "",
      endereco: "",
      gps: null,

      //MAPA

      mapCenter: { lat: -16.713681718591975, lng: -49.26569591634313 },
      mapMarkers: { },

    };
  },

  watch: {

    '$auth.usuario': {
      handler: function(val) {
        if(typeof val == "object" && val != null && val.distribuidores != null)
          this.distribuidores = Object.values(val.distribuidores);
        else
          this.distribuidores = [];
      },
      deep: true,
      immediate: true
    },

    distribuidor: function (val) {

      if (val == null) {
        this.limparFormulario();
      } else {
        this.nome = val.nome;
        this.senha = "";
        this.cep = val.cep;
        this.endereco = val.endereco;
        this.gps = val.gps;

        this.initMapa();
      }

    },

  },

  methods: {

    limparFormulario() {
      //Limpa as variáveis
      this.nome = "";
      this.senha = "";
      this.cep = "";
      this.endereco = "";
      this.gps = "";

      //limpa o objeto
      this.distribuidor = null;
    },

    initMapa() {

      if(this.gps != null) {

        this.mapCenter = { 
          "lat": this.gps.lat,
          "lng": this.gps.lng
        };

        this.mapMarkers = {
          "loja" : {
            "title": this.nome,
            "position": this.mapCenter,
            "icon": "/icos/origemcorrida.png",
            "draggable": true,
          }
        };

        this.centralizar_mapa();

      }

    },

    async validaCep() {

      if(this.cep == "")
        return;

      this.validandoCep = true;

      try {
        
        let response = await axios.get("https://brasilapi.com.br/api/cep/v1/" + this.cep);
        response = response.data;
      
        if(!this.endereco) {
          this.endereco = response.street + ", " + response.neighborhood + ", " + response.city + ", " + response.state;
        }

        response = await this.$apiMega.get("/cepEnderecoGPS?logradouro=" + response.street + "&bairro=" + response.neighborhood + "&cidade=" + response.city + "&uf=" + response.state + "&cep=" + this.cep.replace(/[^0-9]/g,""));
        response = response.data;

        if(response.success) {
          this.gps = response.gps;
        } else {
          this.gps = {
            lat: 0,
            lng: 0
          };
        }

        this.initMapa();

        this.validandoCep = false;

      } catch (error) {

        alert("CEP Inválido! Verifique.");

        this.validandoCep = false;

      }

    },

    cancelarAlteracao() {
      this.limparFormulario();
    },

    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    },

    async criar() {

      if(this.nome == "" || this.senha == "" || this.cep == "" || this.endereco == "" || this.gps == null || this.gps.lat == 0 || this.gps.lng == 0) {
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Todas as informações precisam ser informadas, inclusive a localização GPS!",
          life: 5000,
        });
        return;
      }

      let id = this.getRandomInt(1000, 9999).toString();
      if(this.$auth.usuario.distribuidores != null) {
        while(typeof this.$auth.usuario.distribuidores[id] == "object" )
          id = this.getRandomInt(1000, 9999).toString();
      }

      const d = {
        id: id,
        nome: this.nome,
        senha: this.senha,
        cep: this.cep,
        endereco: this.endereco,
        gps: this.gps,
      };

      await firebase.database().ref("Usuarios").child(this.$auth.usuario.id).child("distribuidores").child(d.id).set(d);

      this.$toast.add({
        severity: "success",
        summary: "Sucesso!",
        detail: "Distribuidor cadastrado!",
        life: 3000,
      });

      this.limparFormulario();

    },

    async alterar() {

      if(this.nome == "" || this.cep == "" || this.endereco == "" || this.gps == null || this.gps.lat == 0 || this.gps.lng == 0) {
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Todas as informações precisam ser informadas, inclusive a localização GPS!",
          life: 5000,
        });
        return;
      }

      let d = {
        nome: this.nome,
        cep: this.cep,
        endereco: this.endereco,
        gps: this.gps,
      };

      if(this.senha != "")
        d.senha = this.senha;

      await firebase.database().ref("Usuarios").child(this.$auth.usuario.id).child("distribuidores").child(this.distribuidor.id).update(d);

      this.$toast.add({
        severity: "success",
        summary: "Sucesso!",
        detail: "Distribuidor atualizado!",
        life: 3000,
      });

      this.limparFormulario();

    },

    async excluir(obj) {

      await firebase.database().ref("Usuarios").child(this.$auth.usuario.id).child("distribuidores").child(obj.id).remove();

      this.$toast.add({
        severity: "success",
        summary: "Sucesso!",
        detail: "Distribuidor excluído!",
        life: 3000,
      });

    },

    alterarForm(obj) {
      this.distribuidor = obj;
      window.scrollTo(0,0);
    },

    centralizar_mapa() {

      let bounds = new this.$refs.mapa.api.LatLngBounds();
      for(const m of Object.values(this.mapMarkers)) {
        bounds.extend(m.position);
      }
      
      this.$refs.mapa.map.panToBounds(bounds);

    },

    atualizaGPS(e) {
      this.gps.lng = e.latLng.lng();
      this.gps.lat = e.latLng.lat();
    },
    
  },
  components: { 
    GoogleMap, 
    Marker
  }
};
</script>

<style>
  #distribuidores tr td:nth-child(1), #distribuidores tr th:nth-child(1) {
    width: 65px;
  }

  #distribuidores tr td:nth-child(3), #distribuidores tr th:nth-child(3) {
    width: 110px;
  }
</style>

<style lang="scss" scoped>

  .loading {
    background: rgba(0,0,0,0.4);
  }

</style>